@font-face {
  font-family: "QuicksandLight";
  src: local("QuicksandLight"),
   url("./Fonts/Quicksand/Quicksand-Light.ttf") format("truetype");
}

@font-face {
  font-family: "QuicksandSemiBold";
  src: local("QuicksandSemiBold"),
   url("./Fonts/Quicksand/Quicksand-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "LexendLight";
  src: local("LexendLight"),
   url("./Fonts/Lexend/Lexend-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LexendExtraLight";
  src: local("LexendExtraLight"),
   url("./Fonts/Lexend/Lexend-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "LexendRegular";
  src: local("LexendRegular"),
   url("./Fonts/Lexend/Lexend-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LexendThin";
  src: local("LexendThin"),
   url("./Fonts/Lexend/Lexend-Thin.ttf") format("truetype");
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.app {
  margin: 0 0;
  background-color: #fcfcfc;
  overflow-x: hidden;
}

/* ELEMENTS */

h1 {
  font-size: 10vh;
  font-family: "LexendLight";
  text-align: left;
}

h2 {
  font-family: "LexendLight";
  color: #fcfcfc;
  font-size: 5vh;
  text-align: center;
}

h3 {
  font-family: "LexendRegular";
  color: #fcfcfc;
  font-size: 8vh;
  text-align: left;
}

h4 {
  font-family: "LexendRegular";
  color: #fcfcfc;
  font-size: 4vh;
  text-align: center;
}

h5 {
  font-size: 4vh;
  text-align: left;
  color: #fcfcfc;
  font-family: "LexendLight";
}

h6 {
  font-size: 4vh;
  color: #fcfcfc;
  font-family: "LexendLight";
}

p {
  font-family: "QuicksandLight";
  font-size: 2vh;
  text-align: left;
  color: #fcfcfc;
}

a {
  font-family: "LexendThin";
  font-size: 2.5vh;
}

a:link {
  color: #fcfcfc;
}

a:hover {
  color: #002a5d;
}

button {
  width: auto;
  height: auto;
  color: #efefef;
  font-size: 2.5vh;
  font-family: "LexendThin";
  background-color: #002a5d;
  border: none;
  padding: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

button:hover {
  background-color: lightblue;
  color: black;
  transition: 0.5s background ease;
}

button:disabled {
  visibility: hidden;
}

ul {
  list-style-type: none;
}

ul li {
  background-image: url("./Images/Blue_TSP_Logo.PNG");
  background-position: 0 1vh;
  background-size: 4vh 4vh;
  background-repeat: no-repeat;
  padding-left: 5vh;
  padding-bottom: 3vh;
}

/* FORMATTING ELEMENTS */

.panel {
  height: 100vh;
  width: 100vw;
}

.panellong {
  min-height: 100vh;
  width: 100vw;
}

.separator {
  width: 100%;
  height: 8px;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.link {
  font-family: "LexendThin";
  font-size: 2.5vh;
  color: #efefef;
  text-align: left;
  text-decoration: underline;
}

.link:hover {
  color: lightblue;
  text-decoration: none;
}

.nostyle {
  text-decoration: none;
}

/* PERSONAL PAGES */

.personal {
  min-height: 100vh;
  background-color: gray;
  width: 100vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  height: 333px;
  width: 333px;
}

.mountainbackground {
  position: relative;
  background-image: url('./Images/MountainBackground.PNG');
  background-position: center;
  background-size: auto 60vh;
  height: 60vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.personal .information {
  margin-top: 5vh;
  margin-bottom: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.personal .socials {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 30vw;
}

@media (orientation: portrait) {
  .personal .socials {
    width: 60vw;
  }
  .mountainbackground {
    background-size: auto 65vh;
    height: 65vh;
  }
}

/* HOME */

.homepage {
  z-index: 1;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.homepage .groupicon {
  width: 30vw;
  height: auto;
}

.videomessage {
  width: 100vw;
  height: 70vh;
  top: 10vh;
  position: relative;
}

.videomessage .message {
  position: absolute;
  left: 10%;
  top: 40%;
  width: 90%;
}

#city_overhead {
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
  z-index: -5;
}

.backgroundlogo {
  background-image: url("./Images/Blue_TSP_Logo.PNG");
  background-size: 70vh auto;
  margin-left: auto;
  margin-right: auto;
  width: 70vh;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage .about {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100vw;
  text-align: center;
  padding-right: 60vw;
  padding-left: 4vw;
}

.homepage .about .aboutimage {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  max-height: 50vh;
  width: auto;
  max-width: 56vw;
}

.homepage .center {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
}

.homepage .box {
  display: flex;
  width: 50vw;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3vh;
  min-height: 40vh;
}

.homepage .learnmore {
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.userbox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 50vh;
}

.companyborder {
  border: #efefef;
  border-style: solid;
  border-width: 0.5vh;
}

.brokerborder {
  border: black;
  border-style: solid;
  border-width: 5vh;
}

.regulatorborder {
  border: #efefef;
  border-style: ridge;
  border-width: 5vh;
}

.updatebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bgutil {
  background-image: none;
}

@media (orientation: portrait) {
  .videomessage {
    background-image: url("./Images/CityOverheadStill.png");
    background-size: 70vh auto;
  }
  .videomessage .message {
    font-size: 5vh;
  }
  #city_overhead {
    display: none;
  }
  .homepage .about {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .homepage .about .aboutimage {
    display: none;
  }
  .homepage .center {
    flex-direction: column;
  }
  .homepage .box {
    width: 95vw;
    min-height: 60vh;
    min-height: none;
  }
  .updatebox {
    height: 80vh;
  }
  .backgroundlogo { 
    width: 70vw;
    height: 70vw;
    background-size: 70vw auto;
  }
  .homepage .icon {
    width: auto;
    height: 30vh;
  }
  .homepage .learnmore {
    width: 100vw;
  }
  .bgutil {
    background-image: url("./Images/CityOverheadStill.png");
    background-size: 100% auto;
  }
}

/* NEWS */

.news {
  margin-top: 10vh;
}

/* PROCESS */

.process {
  z-index: 1;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.process .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 10vw;
}

.process .textbox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 5vh;
}

.process .learnmore {
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* PRODUCT */

.product {
  z-index: 1;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
}

.product .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  padding-left: 20vw;
  padding-right: 20vw;
  padding-top: 15vh;
}

.product .formbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding: 5vh;
}

.product .stepcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 3vh;
}

.stepbox {
  text-align: center;
  padding: 7vh;
  background-color: #002a5d;
  min-width: 25vw;
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.step {
  width: 20vh;
  height: 20vh;
  color: #002a5d;
}

@media (orientation: portrait) {
  .product .center {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .stepbox {
    padding: 2vh;
    min-width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* HEADER */

.header {
  background-color: #fcfcfc;
  background-size: cover;
  height: 10vh;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.htitle {
  position: fixed;
  text-align: right;
  font-size: 3vh;
  color: #000927;
  font-family: "LexendExtraLight";
  left: 5vh;
  top: 1vh;
  width: min-content;
}

.hlogo {
  position: fixed;
  height: 8vh;
  width: 8vh;
  top: 1vh;
  left: 20vh;
}

/* HAMBURGER NAV */

.hamburger {
  display: none;
}

.toggle {
  background: transparent;
  position: absolute;
  border: none;
  color: #000927;
  right: 5vh;
  bottom: 0;
}

.toggle:hover {
  background: transparent;
  color: #4a84f7;
}

.menu {
  background-color: #fcfcfc;
  position: absolute;
  width: 15vh;
  height: 20vh;
  top: 10vh;
  right: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: -10;
}

.icon {
  height: 8vh;
  width: 8vh;
  max-height: 50px;
  max-width: 50px;
}

@media (max-width: 1200px) {
  .hamburger { display: flex; }
}

/* NAVIGATION */

.navbar {
  position: fixed;
  width: 25vw;
  display: none;
  justify-content: space-around;
  flex-direction: row;
  list-style: none;
  left: 30vh;
  top: 3vh;
}

.page {
  color: #000927;
  text-decoration: none;
  font-family: "LexendThin";
  font-size: 3vh;
}

.currentpage {
  color: #000927;
  font-family: "LexendThin";
  font-size: 3vh;
  text-decoration: underline;
}

@media (min-width: 1201px) {
  .navbar { display: flex; }
}

/* NEWS NAVVIGATION */

.newsnavbar {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 35vw;
  padding-right: 35vw;
  list-style: none;
  background-color: rgba(0, 9, 39, 0.9);
}

/* CAROUSEL */

.image-carousel {
  width: 70vw;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-line;
}

.slide {
  max-width: 60vw;
  max-height: 40vh;
  margin: auto;
  bottom: 0;
}

.caption {
  max-width: 100vw;
  font-size: auto;
}

.next {
  position: absolute;
  right: 0;
  bottom: 50%;
}

.prev {
  position: absolute;
  left: 0;
  bottom: 50%;
}

@media (orientation: portrait) {
  .image-carousel { 
    width: 95vw;
    height: auto;
  }
   .slide {
    max-width: 75vw;
    max-height: 30vh;
    margin: auto;
    bottom: 0;
  }
  .caption {
    max-width: 75vw;
  }
}

/* SITEMAP */

.footer {
  height: 60vh;
  background-color: #fcfcfc;
  bottom: 0;
}

.footer .body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

.footer .section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 3vh;
  white-space: pre-line;
}

.footer .links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 20vh;
  padding-top: 2vh;
}

.socialbanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3vh;
  align-items: center;
}

.socialbar {
  width: 35vw;
  height: 4px;
}

@media (orientation: portrait) {
  .socialbar { 
    width: 25vw;
  }
  .footerlabel {
    font-size: 3vh;
  }
}

.slogo {
  height: 25vh;
  width: 25vh;
}

/* FORM */

.form-box {
  display: flex;
  position: relative;
}

.form-box .box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 20vh;
}

.form-box input {
  display: block;
  width: auto;
  max-width: 40vw;
  min-width: 30vw;
  padding: 1vh;
  border: 1;
  border-color: #000927;
  font-size: 2.5vh;
  font-family: "LexendThin";
}

@media (orientation: portrait) {
  .form-box input {
    max-width: 80vw;
    min-width: 60vw;
  }
  .form-box .box {
    height: 10vh;
  }
}

/* LOGOS */

.logo2 {
  height: 80px;
  width: 80px;
}

/* COLOR SCHEME */

.black {
  color: black;
}

.white {
  color: #fcfcfc;
}

.dark-blue {
  color: #000927;
}

.white_bg {
  background-color: #fcfcfc;
}

.dark-blue_bg {
  background-color: #000927;
}

.light-blue {
  background-color: #4a84f7;
}

.maroon {
  background-color: #8a1629;
}

.yellow {
  background-color: #f5c764;
}

.green {
  background-color: #88997a;
}
